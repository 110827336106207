<template>
  <b-container fluid>
    <div>
      <b-card class="justify-content-center">
        <div class="custom-form-flex pb-4">
          <b-form id="alter-form" class="alter-form">
            <b-form-row class="ml-2 pr-2 pb-2 p2">
              <b-col>
                Tipo de Declaração
              </b-col>
              <b-col>
                <div class="label-right">LTCAT/PPP</div>
              </b-col>
            </b-form-row>
            <b-form-row class="ml-2 pr-2 pb-2 p2">
              <b-col>
                Data de Início
              </b-col>
              <b-col>
                <date-picker
                  datePickerId="startDate"
                  :key="startDateKey"
                  :dataValue="form.startDate"
                  :state="validateField('startDate')"
                  @datePickerChangedValue="onChangedStartDate"
                  :required="true"
                />
              </b-col>
            </b-form-row>
            <b-form-row class="ml-2 pr-2 pb-2 p2">
              <b-col>
                Data de Término
              </b-col>
              <b-col>
                <date-picker
                  datePickerId="endDate"
                  :key="endDateKey"
                  :dataValue="form.endDate"
                  :state="validateField('endDate')"
                  @datePickerChangedValue="onChangedEndDate"
                  :required="true"
                />
              </b-col>
            </b-form-row>
          </b-form>
        </div>
      </b-card>
    </div>
    <b-button
      :disabled="loading || this.$store.getters.isAdmin"
      type="button"
      class="m-3"
      variant="primary"
      @click="onSubmit"
    >
      Solicitar declaração
      <i class="fas fa-spinner fa-spin icon" v-show="this.loading"></i>
    </b-button>
    <success-modal
      ref="success-modal"
      description="Sua solicitação de declaração de LTCAT/PPP foi enviada."
      buttonText="Ok, entendi!"
      subdescription="A declaração será enviada para seu e-mail após analise da área de negócio."
      :protocol="this.protocol"
    />
  </b-container>
</template>

<script>
import ServiceRequestType from "../../../../static-data/ServiceRequestType";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import { addDeclaration } from "../../../../services/declaration/declaration-service";
import { DeclarationType } from "../../../../static-data/DeclarationType.js";
import {
  validateField,
  requiredFieldsFilled,
  validateCheckBox,
} from "../../../../utils/validate/validate.js";
import DatePicker from '../../../../components/date-picker/DatePicker.vue';

const DefaultForm = {
  name: null,
  declarationType: "LTCAT/PPP",
  startDate: null,
  endDate: null,
  allTime: false,
};

export default {
  data() {
    return {
      typeSolicitacao: ServiceRequestType.DOCTOR_DECLARATION_LTCAT,
      formSubmited: false,
      loading: false,
      form: { ...DefaultForm },
      validations: {},
      declarationTypeOptions: DeclarationType,
      validateField,
      requiredFieldsFilled,
      validateCheckBox,
      protocol: "",
      endDateKey: null,
      startDateKey: null,
    };
  },
  name: "declaration-informations",
  components: {
    SuccessModal,
    DatePicker,
  },
  async mounted() {
    this.startDateKey = this.form.startDate;
    this.endDateKey = this.form.endDate;
  },
  methods: {
    async onSubmit() {
      this.formSubmited = true;
      this.loading = true;
      if (this.requiredFieldsFilled()) {
        this.form.name = "Solicitação de Declaração";
        this.protocol = await addDeclaration(this.form, this.typeSolicitacao);
        const modal = this.$refs["success-modal"].$children[0];
        modal.show();
        this.formSubmited = false;
      }
      this.loading = false;
    },
    configuredDate() {
      if (this.form.allTime) {
        this.form.startDate = new Date().getDate();
        this.form.endDate = new Date().getDate();
      } else {
        this.form.startDate = null;
        this.form.endDate = null;
      }
    },
    onChangedStartDate(value){
      this.form.startDate = value;
    },
    onChangedEndDate(value) {
      this.form.endDate = value;
    }
  },
};
</script>

<style lang="scss" scoped>
.label-right {
  width: 100%;
  text-align: right;
  padding-right: 2.2em;
}
div.card {
  border-color: transparent;
  border-radius: 1rem;
}
</style>
